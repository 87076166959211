<template>
  <v-card class="bgMinsal mb-16" elevation="0">
    <v-row no-gutters>
      <v-col
        cols="12"
        class="d-flex justify-sm-start justify-center mb-4 mb-md-6"
      >
        <v-btn
          class="button-extra text-capitalize ma-2 v-step-1"
          color="secondary"
          dark
          @click="$router.push({ name: 'solicitar-usuario' })"
          v-if="!haveRole('ROLE_TITULAR_ESTABLECIMIENTO')"
        >
          Solicitar
        </v-btn>
      </v-col>
      <v-col cols="12" lg="4"></v-col>
      <v-col cols="12" lg="8">
        <div class="d-flex flex-md-row flex-column v-step-2">
          <template v-if="false">
            <v-autocomplete
              class="px-4 pb-md-0 pb-4"
              label="Institución"
              hide-details="auto"
              outlined
              no-data-text="Sin instituciones"
            />
            <v-autocomplete
              class="px-3 pb-md-0 pb-4"
              label="Unidad solicitante"
              hide-details="auto"
              outlined
              no-data-text="Sin unidades solicitantes"
            />
          </template>
          <v-autocomplete
            class="px-3 pb-md-0 pb-4"
            label="Tipo de solicitud"
            hide-details="auto"
            outlined
            clearable
            no-data-text="Sin tipos de solicitud"
            :items="tiposSolicitud"
            item-value="id"
            item-text="nombre"
            v-model="filtrosAdicionales.tipo"
            @change="filtrarSolicitudes()"
            :menu-props="{ button: true, offsetY: true }"
          />
          <v-autocomplete
            class="px-3 pb-md-0 pb-4"
            label="Estado"
            hide-details="auto"
            outlined
            clearable
            no-data-text="Sin estados"
            :items="estadosSolicitud"
            item-value="id"
            item-text="nombre"
            v-model="filtrosAdicionales.estado"
            @change="filtrarSolicitudes()"
            :menu-props="{ button: true, offsetY: true }"
          />
          <v-text-field
            class="px-3 px-md-0 pl-md-4"
            label="Buscar empleado"
            hide-details="auto"
            outlined
            maxlength="50"
            v-model="buscarSolicitudText"
            @keyup="setBuscarSolicitudText"
          />
        </div>
      </v-col>
    </v-row>
    <v-card-subtitle class="px-0">
      <span> Listado de solicitudes </span>
    </v-card-subtitle>

    <data-table-component
      v-models:select="cantidadPorPagina"
      v-models:pagina="pagina"
      :headers="headers"
      :items="dataSolicitudes"
      @paginar="paginacion"
      :total_registros="totalPaginas"
    >
      <template v-slot:[`item.estado_log`]="{ item }">
        <div class="py-2">
          <v-chip
            :color="item.estado_solicitud.color"
            :class="
              [3, 4].includes(item.estado_solicitud.id) ? 'white--text' : ''
            "
          >
            {{ item?.estado_solicitud.nombre || "Sin estado" }}
          </v-chip>
          <p class="small--text" v-if="item.estado_solicitud.id == 1 && ![20, 34].includes(item.rol_perfil.id)">
            Requiere aprobación de
            {{ item?.aprobacion_requerida?.nombre.toLowerCase() }}
          </p>
        </div>
      </template>

      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="detalleSolicitud(item.id)"
            >
              <v-icon> mdi-eye </v-icon>
            </v-btn>
          </template>
          <span> Ver </span>
        </v-tooltip>
      </template>
    </data-table-component>
  </v-card>
</template>

<style lang="scss">
.small--text {
  margin: 0 !important;
  color: #c4c4c4;
  font-size: 0.8em;
}
</style>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  name: "TableSolicitudesComponent",
  components: { DataTableComponent },
  data: () => ({
    cantidadPorPagina: 10,
    pagina: 1,
    headers: [
      {
        text: "N°",
        align: "center",
        value: "correlativo",
      },
      {
        text: "Institución",
        align: "left",
        value: "nombre_institucion",
      },
      {
        text: "Unidad solicitante",
        align: "left",
        value: "unidades",
      },
      {
        text: "Empleado",
        align: "left",
        value: "empleado",
      },
      {
        text: "Rol o perfil solicitado",
        align: "left",
        value: "perfil",
      },
      {
        text: "Tipo de solicitud",
        align: "left",
        value: "tipo_solicitud",
      },
      {
        text: "Fecha de solicitud",
        align: "left",
        value: "fecha_hora_solicitud",
      },
      {
        text: "Estado",
        align: "center",
        value: "estado_log",
      },
      {
        text: "Acciones",
        sortable: false,
        align: "center",
        value: "acciones",
      },
    ],
    tiposSolicitud: [],
    estadosSolicitud: [],
    filtrosAdicionales: {
      tipo: null,
      estado: null,
    },
    buscando: null,
    buscarSolicitudText: "",
  }),

  computed: {
    ...mapState("usuariosModule", [
      "dataSolicitudes",
      "totalPaginas",
      "buscarSolicitud",
    ]),
  },

  methods: {
    ...mapMutations("usuariosModule", ["setBuscarSolicitud"]),
    ...mapActions("usuariosModule", ["consultarSolicitudUsuario"]),
    estadoPendiente(value) {
      if (value?.estado_solicitud?.id == 1) {
        return null;
      } else {
        return value?.id_nivel_aprobacion?.nombre;
      }
    },
    async ctlTipoSolicitud() {
      const ctl = await this.services.SolicitudesUsuarios.ctlTipoSolicitud();
      if (ctl?.status === 200) {
        this.tiposSolicitud = ctl?.data;
      }
    },
    async ctlEstadoSolicitud() {
      const ctl = await this.services.SolicitudesUsuarios.ctlEstadoSolicitud();
      if (ctl?.status === 200) {
        this.estadosSolicitud = ctl?.data;
        this.filtrosAdicionales.estado = 0;
      }
    },
    setBuscarSolicitudText() {
      if (this.buscando !== null) clearInterval(this.buscando);
      this.setBuscarSolicitud(this.buscarSolicitudText);
      this.buscando = setInterval(this.filtrarSolicitudes, 500);
    },
    detalleSolicitud(val) {
      this.$router.push({
        name: "visualizar-solicitud-usuario",
        params: { id: val },
      });
    },
    async paginacion(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;

      this.pagina = pagina;
      this.cantidadPorPagina = cantidad_por_pagina;

      let adicionales = {};
      if (this.filtrosAdicionales.estado !== null)
        adicionales.id_estado = this.filtrosAdicionales.estado;
      if (this.filtrosAdicionales.tipo !== null)
        adicionales.id_tipo_solicitud = this.filtrosAdicionales.tipo;
      if (this.buscarSolicitudText !== "")
        adicionales.busqueda = this.buscarSolicitudText;

      filtros = {
        pagination: true,
        page: pagina,
        per_page: cantidad_por_pagina,
        ...adicionales,
      };

      await this.consultarSolicitudUsuario(filtros);
    },
    filtrarSolicitudes() {
      if (this.buscando !== null) clearInterval(this.buscando);
      this.paginacion({
        pagina: 1,
        cantidad_por_pagina: 10,
      });
    },

    async initCreated() {
      await this.consultarSolicitudUsuario();
      await this.ctlTipoSolicitud();
      await this.ctlEstadoSolicitud();
    },
  },

  created() {
    this.initCreated();
  },
};
</script>