<template>
  <v-dialog persistent max-width="1000" v-model="showDialogDetailValue">
    <v-card>
      <v-toolbar color="primary" dark>
        Detalle de información de usuario
      </v-toolbar>
      <v-card-text v-if="userDetail">
        <v-row class="mt-1">
          <v-col cols="12" md="6" class="d-flex flex-column">
            <span> Nombre </span>
            <span class="text-body-1 secondary--text"> {{ fullName }} </span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-column">
            <span> Identificación tributaria según origen </span>
            <span class="text-body-1 secondary--text">
              {{ userDetail?.DocumentoPersonas?.[0]?.TipoDocumento.nombre }}
            </span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-column">
            <span> Número de documento </span>
            <span class="text-body-1 secondary--text">
              {{ userDetail?.DocumentoPersonas?.[0]?.numero_documento }}
            </span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-column">
            <span> Estado </span>
            <span class="text-body-1 secondary--text">
              {{ estadoUsuario }}
            </span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-column">
            <span> Correo Electrónico </span>
            <span class="text-body-1 secondary--text">
              {{ userDetail?.Empleados?.[0]?.Usuario?.email }}
            </span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-column">
            <span>Teléfono</span>
            <span class="text-body-1 secondary--text">
              {{ userDetail?.telefono }}
            </span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-column">
            <span>Profesión</span>
            <span class="text-body-1 secondary--text">
              {{ userDetail?.Profesione?.nombre }}
            </span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-column">
            <span>Título / Certificado</span>
            <span class="text-body-1 secondary--text">
              <a
                @click="obtainLinkDocument(userDetail?.url_documento_cargo)"
                class="secondary--text"
                target="_blank"
                v-if="userDetail?.url_documento_cargo"
              >
                Visualizar archivo <v-icon> mdi-download </v-icon>
                <v-icon></v-icon>
              </a>
              <span v-else> Sin documento </span>
            </span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-column">
            <span>Rol o perfil</span>
            <span class="text-body-1 secondary--text">
              {{ userDetail?.Empleados?.[0]?.Usuario?.Perfils[0]?.nombre }}
            </span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-column">
            <span>Fecha de caducidad</span>
            <span class="text-body-1 secondary--text">
              {{
                moment(
                  userDetail?.Empleados?.[0]?.Usuario?.fecha_caducidad,
                  "YYYY-MM-DD"
                ).format("dddd LL")
              }}
            </span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-column">
            <span>Cargo Funcional</span>
            <span class="text-body-1 secondary--text">
              {{ userDetail?.Empleados?.[0]?.Usuario?.cargo_funcional }}
            </span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-column">
            <span>
              División, unidad, departamento, o área a la que pertenece
            </span>
            <span class="text-body-1 secondary--text">
              {{ unidadesUsuario }}
            </span>
          </v-col>
          <v-col cols="12" class="d-flex flex-column">
            <span>Información Adicional</span>
            <span class="text-body-1 secondary--text">
              {{
                CleanValueFtn(
                  userDetail?.Empleados?.[0]?.Usuario?.informacion_adicional,
                  "Sin información adicional"
                )
              }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn class="text-capitalize" text @click="closeDialog">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
export default {
  name: "DialogUserDetail",
  props: {
    showDialogDetailValue: {
      type: Boolean,
      default: false,
    },
    userDetail: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    fullName() {
      return `
        ${this.userDetail?.primer_nombre ? this.userDetail?.primer_nombre : ""} 
        ${this.userDetail?.segundo_nombre ? this.userDetail?.segundo_nombre : ""} 
        ${this.userDetail?.primer_apellido ? this.userDetail?.primer_apellido : ""} 
        ${
          this.userDetail?.segundo_apellido ? this.userDetail?.segundo_apellido : ""
        } 
        ${
          this.userDetail?.apellido_casada ? this.userDetail?.apellido_casada : ""
        }
      `;
    },
    estadoUsuario() {
      return !this.userDetail?.Empleados?.[0]?.Usuario?.is_suspended
        ? "Habilitado"
        : "Deshabilitado";
    },
    unidadesUsuario() {
      // return units in string
      let units = [];
      this.userDetail?.Empleados?.[0]?.EmpleadoUnidads?.forEach((unit) => {
        units.push(unit?.Unidad?.nombre);
      });
      return units.join(", ");
    },
  },
  methods: {
    moment,
    closeDialog() {
      this.$emit("closeDialogDetail");
    },

    CleanValueFtn(value, message) {
      if (value == null || value == undefined || value == "") {
        return message;
      } else if (value == "null" || value == "undefined") {
        return message;
      } else {
        return value;
      }
    },

    async obtainLinkDocument(route) {
      try {
        const response = await this.services.Usuarios.getUrlDocumento({
          ruta: route,
        });
        if (response) {
          const blob = new Blob([response.data], {
            type: "application/pdf",
          });

          const file = new File([blob], "documento.pdf", {
            type: "application/pdf",
          });

          // Descargar el archivo
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = route.toString();
          link.click();

          this.document = file;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>