<template>
  <section fluid>
    <h3 class="mb-8">Aprobación de solicitudes de titular</h3>
    <v-row class="d-flex justify-end">
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="estado_selected"
          outlined
          label="Estado"
          item-value="id"
          item-text="nombre"
          hide-details
          clearable
          @change="getSolicitudes()"
          :items="ctl_estado"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="institucion_selected"
          outlined
          label="Institución"
          item-value="id"
          item-text="nombre_completo"
          hide-details
          clearable
          @change="getSolicitudes()"
          :items="instituciones"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="documento"
          outlined
          label="Documento"
          hide-details
          clearable
          placeholder="########-#"
          v-mask="'########-#'"
        />
      </v-col>
    </v-row>
    <data-table-component
      v-models:select="perPage"
      v-models:pagina="page"
      :headers="headers"
      :items="items"
      @paginar="paginate"
      :total_registros="totalRows"
      class="mb-8"
    >
      <template v-slot:item.institucion="{ item }">
        <p class="mb-0">
          {{ item.codigo_institucion  }} - 
          {{ item.institucion }}
        </p>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ formatDate(item.created_at) }}
      </template>

      <template v-slot:[`item.estado`]="{ item }">
        <v-chip :color="item.estado_color" ><span class="bgWhite--text">{{ item.estado }}</span></v-chip>
      </template>

      <template v-slot:[`item.acciones`]="{ item }">
        <v-btn icon @click="getSolicitudTitular(item.id)">
          <v-icon color="secondary">mdi-eye</v-icon>
        </v-btn>
      </template>
    </data-table-component>

    <v-dialog max-width="600" persistent v-model="detallesSolicitud">
      <v-card class="pt-4 pb-4">
        <v-card-text class="mb-4">
          <h5 class="text-h5 mb-4">{{ solicitud?.nombre }}</h5>

          <v-divider></v-divider>

          <div class="mb-4 mt-4">
            <p class="mb-0">Institución</p>
            <p class="text-subtitle-1 secondary--text mt-0">
              {{ solicitud?.institucion }}
            </p>
          </div>

          <div class="mb-4">
            <p class="mb-0">Correo electrónico</p>
            <p class="text-subtitle-1 secondary--text mt-0">
              {{ solicitud?.correo_electronico }}
            </p>
          </div>

          <div class="mb-4">
            <p class="mb-0">Teléfono</p>
            <p class="text-subtitle-1 secondary--text mt-0">
              {{ solicitud?.telefono }}
            </p>
          </div>

          <div class="mb-4">
            <p class="mb-0">Profesión</p>
            <p class="text-subtitle-1 secondary--text mt-0">
              {{ solicitud?.Profesione?.nombre }}
            </p>
          </div>

          <div class="mb-4">
            <p class="mb-0">Cargo funcional</p>
            <p class="text-subtitle-1 secondary--text mt-0">
              {{ solicitud?.cargo_funcional }}
            </p>
          </div>

          <div class="mb-4">
            <p class="mb-0">Información adicional</p>
            <p class="text-subtitle-1 secondary--text mt-0">
              {{
                solicitud?.informacion !== "null" ? solicitud?.informacion : "-"
              }}
            </p>
          </div>

          <div class="mb-4">
            <p class="mb-0">Título / Comprobante de nombramiento</p>
            <v-btn text @click="descargarDocumento()">
              <v-icon color="secondary">mdi-download</v-icon> Descargar
              documento
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <!-- HACK: Se debe mejorar la condición para mostrar los botones, convertir a objeto -->
            <template v-if="solicitud?.id_estado == 1">
              <v-col cols="12" sm="6" v-show="haveRole('ROLE_APROBACION_SOLICITUDES_TITULAR')">
                <v-btn color="secondary" @click="confirmDialog = true" block>
                  Aprobar
                </v-btn>
              </v-col>
              <v-col cols="12" sm="3" v-show="haveRole('ROLE_APROBACION_SOLICITUDES_TITULAR')">
                <v-btn
                  block
                  outlined
                  color="error"
                  @click="deniedDialog = true"
                >
                  Rechazar
                </v-btn>
              </v-col>
            </template>

            <v-col cols="12" sm="3">
              <v-btn
                block
                text
                color="secondary"
                @click="(detallesSolicitud = false), (solicitud = {})"
                >Cerrar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialogoConfirmacion
      :show="confirmDialog"
      title="¿Desea aprobar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Continuar"
      @close="confirmDialog = false"
      @confirm="putSolicitudTitular(3)"
    />

    <dialogoConfirmacion
      :show="deniedDialog"
      title="¿Desea rechazar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Continuar"
      @close="deniedDialog = false"
      @confirm="putSolicitudTitular(4)"
    />
  </section>
</template>

<script>
import moment from "moment";
import DataTableComponent from "@/components/DataTableComponent.vue";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";

export default {
  name: "SolcitudesTitularComponent",
  components: {
    DataTableComponent,
    dialogoConfirmacion,
  },
  data: () => ({
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      {
        text: "Institución",
        value: "institucion",
      },
      {
        text: "Documento de identidad",
        value: "numero_documento",
      },
      {
        text: "Fecha de solicitud",
        value: "created_at",
      },
      {
        align: "center",
        text: "Estado",
        value: "estado",
      },
      {
        align: "center",
        text: "Acciones",
        value: "acciones",
      },
    ],
    items: [],
    page: 1,
    perPage: 10,
    totalRows: 0,
    detallesSolicitud: false,
    confirmDialog: false,
    deniedDialog: false,
    solicitud: {},

    ctl_estado: [],
    ctl_instituciones: [],

    estado_selected: null,
    institucion_selected: null,
    documento: null,
  }),
  computed: {
    instituciones() {
      return this.ctl_instituciones.map((institucion) => ({
        ...institucion,
        nombre_completo: `${institucion.codigo} - ${institucion.nombre}`,
      }));
    },
  },
  methods: {
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getSolicitudes();
    },
    async getSolicitudes() {

      const { status, data, headers } =
        await this.services.Usuarios.getSolicitudesTitulares({
          page: this.page,
          per_page: this.perPage,
          id_estado: this.estado_selected ? this.estado_selected : undefined,
          id_institucion: this.institucion_selected
            ? this.institucion_selected
            : undefined,
          numero_documento: this.documento ? this.documento : undefined,
        });

      if (status == 200) {
        this.items = data;
        this.totalRows = Number(headers.total_rows);
      }

    },
    async getSolicitudTitular(id) {

      const { status, data } = await this.services.Usuarios.getSolicitudTitular(
        id
      ).catch(() => {

      });

      if (status == 200) {
        this.solicitud = data;
        this.detallesSolicitud = true;
      }

    },
    formatDate(datetime) {
      return moment(datetime).format("DD-MM-YYYY HH:mm A");
    },
    async descargarDocumento() {

      const { status, data, headers } =
        await this.services.Usuarios.descargarTitulo({
          ruta: this.solicitud.ruta_documento,
          disk: "usuarios",
        })

      if (status === 200) {
        const blob = new Blob([data], {
          type: headers["content-type"],
        });

        const file = new File(
          [blob],
          `tituloNombramiento_${this.solicitud.id}`,
          {
            type: headers["content-type"],
          }
        );

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = "";
        link.click();
      }


    },
    async putSolicitudTitular(id_estado) {

      const { status, data } = await this.services.Usuarios.putSolicitudTitular(
        this.solicitud.id,
        {
          id_estado,
        }
      ).catch(() => {

      });

      if (status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Se ha actualizado el estado de la solicitud",
        });
        this.confirmDialog = false;
        this.deniedDialog = false;
        this.detallesSolicitud = false;
        this.solicitud = null;
        this.getSolicitudes();
      }

    },

    async FetchCtlEstado() {
      const response =
        await this.services.SolicitudesUsuarios.ctlEstadoSolicitud();
      if (response?.status === 200) {
        this.ctl_estado = response?.data;
      }
    },

    async FetchCtlInstituciones() {
      const response = await this.services.Usuarios.getInstituciones();
      if (response) {
        this.ctl_instituciones = response.data;
      }
    },

    initCreated() {
      this.getSolicitudes();
      this.FetchCtlEstado();
      this.FetchCtlInstituciones();
    },
  },

  watch: {
    documento() {
      if (this.documento) {
        if (this.documento.length >= 4) {
          this.getSolicitudes();
        }
      } else {
        this.getSolicitudes();
      }
    },
  },

  created() {
    this.initCreated();
  },
};
</script>