<template>
    <v-dialog v-model="internalOpen" max-width="700" persistent>
        <div style="background-color: #FFFFFF; padding: 1em; position: relative;">
            <div style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="px-4 py-4"
                    @click.stop="cerrarModal" 
                    text
                    rounded
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <div class="px-1 mt-4">
                <v-form ref="form" @submit.prevent="suspenderOficial">
                    <v-file-input
                        v-model="form.adjunto"
                        label="Adjunto"
                        placeholder="Seleccione un PDF"
                        :error-messages="adjuntoErrors"
                        @blur="$v.form.adjunto.$touch()"
                        outlined
                        light
                        accept="application/pdf"
                    />
    
                    <div class="mt-8 mb-1 d-flex justify-center align-center flex-wrap" style="gap: 16px;">
                        <v-btn 
                            class="flex-grow-1 flex-shrink-1" 
                            color="secondary"
                            @click.stop="cerrarModal"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn 
                            class="flex-grow-1 flex-shrink-1" 
                            color="primary"
                            type="submit"
                        >
                            Continuar
                        </v-btn>
                    </div>
                </v-form>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { isNil } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/form-validation';

export default {
    name: 'ModalSuspenderOficial',
    emits: ['on-visibility-change', 'on-save'],
    validations() {
        return ({
            form: { 
                adjunto: { ...this.adjuntoRequerido && { required } },
            },
        });
    },
    props: {
        isOpen: { type: Boolean },
        adjuntoRequerido: { type: Boolean, default: true },
    },
    data: () => ({
        internalOpen: false,
        form: {
            adjunto: null,
        },
    }),
    computed: {
        adjuntoErrors() {
            return new Validator(this.$v.form.adjunto).detect().getResult();
        },
    },
    methods: {
        cerrarModal() {
            this.$v.form.$reset();
            this.form = { adjunto: null };
            this.internalOpen = false;
        },
        suspenderOficial() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.$emit('on-save', { adjunto: this.form.adjunto });
            this.cerrarModal();
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },
}
</script>