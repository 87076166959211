<template>
    <Fragment>
        <v-dialog v-model="isOpen" max-width="800px" persistent>
            <v-card>
                <v-card-title class="px-4 py-4">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-4 py-4">
                    <v-file-input
                        v-model="form.adjunto"
                        label="PDF"
                        placeholder="Seleccione un PDF"
                        outlined
                        light
                        :error-messages="adjuntoErrors"
                        @blur="$v.form.adjunto.$touch()"
                        accept="application/pdf"
                    />

                    <v-autocomplete
                        v-model="form.id_perfil"
                        :items="catalogoPerfiles"
                        label="Perfil *"
                        item-value="id"
                        item-text="nombre"
                        :error-messages="id_perfilErrors"
                        @change="$v.form.id_perfil.$touch()"
                        @blur="$v.form.id_perfil.$touch()"
                        outlined
                    />
                    <div class="d-flex justify-center align-center mt-4" style="gap: 16px;">
                        <v-btn 
                            class="flex-grow-1 flex-shrink-1" 
                            color="secondary" 
                            large
                            @click.stop="cerrarModal"
                        >
                            Cerrar
                        </v-btn>
                        <v-btn 
                            class="flex-grow-1 flex-shrink-1" 
                            color="primary" 
                            large
                            @click.stop="confirmarCambioDePerfil"
                        >
                            Guardar
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ConfirmationModalComponent
            :is-open="confirmacionCambioPerfil"
            description="¿Desea continuar con el cambio de perfil?"
            :is-loading="cambioDePerfil.isLoading"
            @cancel="confirmacionCambioPerfil = false"
            @confirm="cambiarDePerfil"
        />
    </Fragment>
</template>
<script>
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';
import { required } from 'vuelidate/lib/validators';
import { Fragment } from 'vue-fragment';
import { mapState } from 'vuex';
import { Validator } from '@/utils/form-validation';
import { convertToFormData } from '@/utils/data';

const estadoInicialFormulario = {
    adjunto: null,
    id_perfil: null,
}

export default {
    name: 'ModalCambioPerfil',
    emits: ['on-save'],
    validations: {
        form: {
            id_perfil: { required },
            adjunto: { required },
        },
    },
    components: { ConfirmationModalComponent, Fragment },
    props: {
        isOpen: { type: Boolean },
        idUsuario: { type: [String, Number] },
        soloPerfilesCumplimiento: { type: Boolean, default: false },
    },
    data: () => ({
        form: { ...estadoInicialFormulario },
        confirmacionCambioPerfil: false,
        cambioDePerfil: createLoadable(null),
        cargaUtil: null,
    }),
    computed: {
        ...mapState("usuariosModule", ["perfiles"]),
        catalogoPerfiles() {
            return this.soloPerfilesCumplimiento 
                ? this.perfiles.filter((perfil) => [20, 34].includes(perfil.id))
                : this.perfiles;
        },
        adjuntoErrors() {
            return new Validator(this.$v.form.adjunto).detect().getResult();
        },
        id_perfilErrors() {
            return new Validator(this.$v.form.id_perfil).detect().getResult();
        },
    },
    methods: {
        cerrarModal() {
            this.$emit('update:is-open', false);
            this.$v.$reset();
            this.form = { ...estadoInicialFormulario };
        },
        confirmarCambioDePerfil() {
            this.$v.$touch();

            if (this.$v.$invalid) return;
            
            this.confirmacionCambioPerfil = true;
            this.cargaUtil = { ...this.form };
            this.cerrarModal();
        },
        async cambiarDePerfil() {
            toggleLoadable(this.cambioDePerfil);
            const { data } = await this.services.VerificacionDocumentosOdc.crearSolicitudCambioPerfil(this.idUsuario, convertToFormData(this.cargaUtil));
            this.confirmacionCambioPerfil = false;
            setLoadableResponse(this.cambioDePerfil, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) this.$emit('on-save');
        },
    },
}
</script>