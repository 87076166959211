<template>
  <section>
    <v-row>
      <v-col cols="12" sm="2">
        <v-text-field
          v-model="nombre"
          label="Nombre"
          outlined
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" sm="2">
        <v-text-field
          v-model="correo"
          label="Correo"
          outlined
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" sm="2">
        <v-text-field
          v-model="documento"
          label="Documento"
          outlined
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" sm="2">
        <v-autocomplete
          v-model="institucion"
          outlined
          label="Institución"
          item-value="id"
          item-text="nombre"
          hide-details
          clearable
          :items="ctl_instituciones"
        />
      </v-col>
      <v-col cols="12" sm="2">
        <v-autocomplete
          v-model="perfil"
          outlined
          label="Perfil"
          item-value="id"
          item-text="nombre"
          hide-details
          clearable
          :items="ctl_perfiles"
        />
      </v-col>
      <v-col cols="12" sm="1">
        <v-btn height="97%" @click="getUsersRequest" color="secondary" block>
          <v-icon color="white">mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <data-table-component
      v-models:select="perPage"
      v-models:pagina="page"
      :total_registros="totalRows"
      :headers="headers"
      :items="solicitudes"
      @paginar="paginate"
      hide-default-footer
    >
      <template v-slot:[`item.solicitante`]="{ item }">
        {{ item.solicitante?.email }} -
        {{ item.solicitante?.perfiles.map((p) => p.nombre).join(", ") }}
      </template>

      <template v-slot:[`item.unidades`]="{ item }">
        {{ item.unidades?.map((u) => u.nombre).join(", ") }}
      </template>

      <template v-slot:[`item.id_nivel_aprobacion`]="{ item }">
        {{ item.id_nivel_aprobacion?.nombre }}
      </template>

      <template v-slot:[`item.acciones`]="{ item }">
        <!-- VER DETALLES -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="selectedRequest = item"
            >
              <v-icon> mdi-eye </v-icon>
            </v-btn>
          </template>
          <span> Ver </span>
        </v-tooltip>
        <!-- ELIMINAR SOLICITUD -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="selectedRequestToDelete = item"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span> Eliminar </span>
        </v-tooltip>
      </template>
    </data-table-component>

    <v-dialog max-width="600" persistent v-model="showSelectedRequest">
      <v-card class="pt-4 pb-4" max-height="80vh" style="overflow-y: scroll">
        <v-card-text class="mb-4">
          <pre style="overflow-y: scroll">{{ selectedRequest }}</pre>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-btn
                block
                text
                color="secondary"
                @click="selectedRequest = null"
              >
                Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialogoConfirmacion
      :show="showDeleteRequestConfirmation"
      title="¿Desea eliminar la solicitud de usuario seleccionada?"
      message="De confirmarse la siguiente acción los cambios no serán reversibles"
      btnConfirmar="Eliminar solicitud de usuario"
      @close="selectedRequestToDelete = null"
      @confirm="deleteUserRequest()"
    />
  </section>
</template>

<script>
  import DataTableComponent from "@/components/DataTableComponent";
  import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";

  export default {
    name: "AdminSolicitudUsuariosComponent",
    components: {
      DataTableComponent,
      dialogoConfirmacion,
    },
    data: () => ({
      perPage: 10,
      page: 1,
      totalRows: 0,

      headers: [
        {
          text: "Tipo de solicitud",
          align: "left",
          value: "tipo_solicitud",
        },
        {
          text: "Empleado",
          align: "left",
          value: "empleado",
        },
        {
          text: "Documento",
          align: "left",
          value: "documento",
        },
        {
          text: "Correo",
          align: "left",
          value: "correo_electronico",
        },
        {
          text: "Solicitante",
          align: "left",
          value: "solicitante",
        },
        {
          text: "Institución",
          align: "left",
          value: "nombre_institucion",
        },
        {
          text: "Unidad",
          align: "left",
          value: "unidades",
        },
        {
          text: "Perfil",
          align: "left",
          value: "perfil",
        },
        {
          text: "Estado",
          align: "left",
          value: "estado",
        },
        {
          text: "Nivel aprobación",
          align: "left",
          value: "id_nivel_aprobacion",
        },
        {
          text: "Acciones",
          sortable: false,
          align: "center",
          value: "acciones",
        },
      ],

      solicitudes: [],
      ctl_instituciones: [],
      institucion: null,
      ctl_perfiles: [],
      perfil: null,
      correo: null,
      nombre: null,
      documento: null,

      selectedRequest: null,
      showSelectedRequest: false,

      selectedRequestToDelete: null,
      showDeleteRequestConfirmation: false,
    }),
    methods: {
      paginate(params) {
        const { cantidad_por_pagina, pagina } = params;
        this.perPage = cantidad_por_pagina;
        this.page = pagina;

        this.getUsersRequest();
      },

      async getUsersRequest() {
        const filters = {
          id_institucion: this.institucion,
          id_perfil: this.perfil,
          correo: this.correo,
          nombre: this.nombre,
          documento: this.documento,
        };

        Object.entries(filters).forEach(([, v]) => {
          if (v !== null && this.page !== 1) this.page = 1;
        });


        const { status, data, headers } =
          await this.services.Usuarios.getAllUsersRequest({
            page: this.page,
            per_page: this.perPage,
            ...filters,
          });

        if (status == 200) {
          this.solicitudes = data;
          this.totalRows = Number(headers.total_rows);
        }

      },

      async FetchCtlInstituciones() {
        const response = await this.services.Usuarios.getInstituciones();
        if (response) this.ctl_instituciones = response.data;
      },

      async FetchCtlPerfiles() {
        const response = await this.services.Usuarios.getPerfilRol({ paginacion: false });
        if (response) this.ctl_perfiles = response.data;
      },

      async deleteUserRequest() {

        const response = await this.services.Usuarios.deleteUserRequest(this.selectedRequestToDelete.id);
        if (response?.status == 200) {
          this.selectedRequestToDelete = null;
          this.getUsersRequest();
        }

      },

      async initCreated() {
        await this.getUsersRequest();
        await this.FetchCtlInstituciones();
        await this.FetchCtlPerfiles();
      },
    },
    watch: {
      selectedRequest(v) {
        this.showSelectedRequest = v !== null ? true : false;
      },
      selectedRequestToDelete(v) {
        this.showDeleteRequestConfirmation = v !== null ? true : false;
      },
    },
    created() {
      this.initCreated();
    },
  };
</script>
