<template>
  <section fluid>
    <v-row class="mt-4">
      <v-col cols="12" sm="4" md="3">
        <v-btn block color="secondary" @click="cleanModalAddSupervisor(), (modalNuevoSupervisor = true)"><v-icon
            color="white">mdi-plus</v-icon>
          Agregar</v-btn>
      </v-col>
    </v-row>

    <data-table-component v-models:select="perPage" v-models:pagina="page" :headers="headers" :items="items"
      @paginar="paginate" :total_registros="totalRows" class="mb-8">
      <template v-slot:[`item.acciones`]="{ item }">
        <v-btn icon @click="
          (modalEditSupervisor = true),
          (user_selected = item),
          getRolesUsuario()
          ">
          <v-icon color="secondary">mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="(modalDeleteSupervisor = true), (user_selected = item)">
          <v-icon color="secondary">mdi-delete</v-icon>
        </v-btn>
      </template>
    </data-table-component>

    <!-- AGREGAR NUEVO SUPERVISOR -->
    <v-dialog v-model="modalNuevoSupervisor" max-width="600" persistent>
      <v-card>
        <div class="headerIcon d-flex justify-end pr-4 pt-4">
          <v-icon class="secondary--text" @click="(modalNuevoSupervisor = false), (user_selected = null)">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <div>
            <p class="text-h6 secondary--text">Agregar supervisor</p>
            <span>
              Digite el correo para el usuario que será supervisor del proceso
            </span>
            <v-autocomplete class="mt-6" v-model="user_selected" :items="usuarios" :loading="loading_ctl_usuarios"
              :search-input.sync="search_ctl_usuarios" label="Correo electrónico"
              placeholder="Digite el correo del usuario" outlined item-text="email" return-object
              @blur="$v.user_selected.$touch()" :error-messages="userSelectedError" />
            <v-text-field class="mb-0" label="Nombre completo" outlined readonly
              :value="nombreSupervFtn(user_selected)" />
            <div class="d-flex flex-row">
              <v-checkbox class="mt-0 mr-2" label="Supervisor de procesos" v-model="role_supervisor" />
              <v-checkbox class="mt-0 mr-2" label="Acceso a RUPES" v-model="acceso_rupes" />
            </div>
            <div class="d-flex flex-row">
              <v-checkbox class="mt-0 mr-2" label="Agregar alerta a proveedores" v-model="add_alert_prov" />
              <v-checkbox class="mt-0 mr-2" label="Exportar reporte de proveedores registrados"
                v-model="rpt_proveedores" />
            </div>
            <div class="d-flex flex-row">
              <v-checkbox class="mt-0 mr-2" label="Crear procesos con reserva" v-model="viewProcesosReserva" />
              <v-checkbox class="mt-0 mr-2" label="Indicadores institucionales" v-model="indicadoresInstitucionales" />
            </div>
            <div class="d-flex flex-row">
              <v-checkbox class="mt-0 mr-2" label="Auditoría de procesos" v-model="auditoriaProcesos" />
            </div>
          </div>
          <!-- acciones -->
          <v-row class="d-flex justify-center mx-sm-4 mb-6 mt-6">
            <v-btn class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4" color="secondary" outlined
              @click="(modalNuevoSupervisor = false), (user_selected = null)">
              Cancelar
            </v-btn>
            <v-btn class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4" color="secondary"
              @click="agregarSupervisor">
              Registrar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- EDITAR SUPERVISOR -->
    <v-dialog v-model="modalEditSupervisor" max-width="600" persistent>
      <v-card>
        <div class="headerIcon d-flex justify-end pr-4 pt-4">
          <v-icon class="secondary--text" @click="(modalEditSupervisor = false), (user_selected = null)">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <div>
            <p class="text-h6 secondary--text">Editar supervisor</p>
            <div class="d-flex flex-row">
              <v-checkbox class="mt-0 mr-4" label="Supervisor de procesos" v-model="role_supervisor"
                @change="updateRoles(403, role_supervisor)" />
              <v-checkbox class="mt-0 mr-4" label="RUPES" v-model="acceso_rupes"
                @change="updateRoles(405, acceso_rupes)" />
            </div>
            <div class="d-flex flex-row">
              <v-checkbox class="mt-0 mr-4" label="Agregar alerta a proveedores" v-model="add_alert_prov"
                @change="updateRoles(406, add_alert_prov)" />
              <v-checkbox class="mt-0 mr-4" label="Exportar reporte de proveedores registrados" v-model="rpt_proveedores"
                @change="updateRoles(407, rpt_proveedores)" />
            </div>
            <div class="d-flex flex-row">
              <v-checkbox class="mt-0 mr-4" label="Crear procesos con reserva" v-model="viewProcesosReserva"
                @change="updateRoles(404, viewProcesosReserva)" />
              <v-checkbox class="mt-0 mr-4" label="Indicadores institucionales" v-model="indicadoresInstitucionales"
                @change="updateRoles(426, indicadoresInstitucionales)" />
            </div>
            <div class="d-flex flex-row">
              <v-checkbox class="mt-0 mr-4" label="Auditoría de procesos" v-model="auditoriaProcesos"
                @change="updateRoles(427, auditoriaProcesos)" />
            </div>
          </div>
          <!-- acciones -->
          <v-row class="d-flex justify-center mx-sm-4 mb-6 mt-6">
            <v-btn class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4" color="secondary"
              @click=" (modalEditSupervisor = false)">
              Finalizar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <dialogoConfirmacion :show="modalDeleteSupervisor" title="¿Desea proseguir con la eliminación del supervisor?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles." btnConfirmar="Aceptar"
      @close="(modalDeleteSupervisor = false), (user_selected = null)" @confirm="deleteSupervisor()" />
  </section>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AdminSupervisoresComponent",
  components: {
    DataTableComponent,
    dialogoConfirmacion,
  },

  validations: {
    user_selected: {
      required,
    },
  },

  data: () => ({
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      {
        text: "Institución",
        value: "institucion",
      },
      {
        align: "center",
        text: "Acciones",
        value: "acciones",
      },
    ],
    items: [],
    page: 1,
    perPage: 10,
    totalRows: 0,

    modalNuevoSupervisor: false,
    modalDeleteSupervisor: false,
    loading_ctl_usuarios: false,
    search_ctl_usuarios: "",
    usuarios: [],
    user_selected: null,
    viewProcesosReserva: null,
    modalEditSupervisor: false,
    rolesUsuarioSelected: [],
    acceso_rupes: false,
    add_alert_prov: false,
    rpt_proveedores: false,
    role_supervisor: false,
    indicadoresInstitucionales: false,
    auditoriaProcesos: false,
    timer: null,
  }),
  computed: {
    userSelectedError() {
      const error = [];
      if (!this.$v.user_selected.$dirty) return error;
      !this.$v.user_selected.required && error.push("El campo es requerido");
      return error;
    },
  },
  methods: {
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getSupervisores();
    },
    async getSupervisores() {

      const { status, data, headers } =
        await this.services.Usuarios.getSupervisores({
          page: this.page,
          per_page: this.perPage,
        });

      if (status == 200) {
        this.items = data;
        this.totalRows = Number(headers.total_rows);
      }

    },
    async buscarUsuario(search_param) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        const response = await this.services.Usuarios.buscarUsuarios({
          busqueda: search_param,
        });

        if (response?.status === 200) {
          const { data } = response;
          this.usuarios = data;
        }
      }, 500);
    },
    nombreSupervFtn(superv_param) {
      if (typeof superv_param !== "object") {
        console.error("El parametro no es un objeto");
        return
      }

      let infoSuperv;
      if (superv_param?.EmpleadoUnidad != null) {
        infoSuperv = superv_param?.EmpleadoUnidad?.Empleado?.Persona;
      } else {
        infoSuperv = superv_param;
      }
      if (infoSuperv) {
        let name = [
          infoSuperv.primer_nombre ?? null,
          infoSuperv.segundo_nombre ?? null,
          infoSuperv.primer_apellido ?? null,
          infoSuperv.segundo_apellido ?? null,
          infoSuperv.apellido_casada ?? null
        ];

        name = name.filter((item) => item !== null);

        return name.join(" ");
      } else {
        return "";
      }
    },
    async agregarSupervisor() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      const roles = [];
      if (this.role_supervisor) roles.push(403);
      if (this.viewProcesosReserva) roles.push(404);
      if (this.acceso_rupes) roles.push(405);
      if (this.add_alert_prov) roles.push(406);
      if (this.rpt_proveedores) roles.push(407);
      if (this.indicadoresInstitucionales) roles.push(426);
      if (this.auditoriaProcesos) roles.push(427);

      if (roles.length === 0) {
        this.temporalAlert({
          show: true,
          type: "warning",
          message: "Debe seleccionar al menos un rol",
        });
        return;
      }


      const response = await this.services.Usuarios.postUsuarioRol({
        id_roles: roles,
        id_usuario: this.user_selected?.id_usuario,
      });

      if (response?.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Rol agregado a usuario con éxito",
        });
        this.getSupervisores();
        this.cleanModalAddSupervisor();
        this.modalNuevoSupervisor = false;
      }
    },
    async deleteSupervisor() {

      const response = await this.services.Usuarios.deletetUsuarioRol({
        all: true,
        id_usuario: this.user_selected?.id,
      });

      if (response?.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: response.data.message,
        });
        this.user_selected = null;
        this.getSupervisores();
        this.modalDeleteSupervisor = false;
      }
    },
    async getRolesUsuario() {

      const response = await this.services.Usuarios.getRolesUsuario(
        this.user_selected?.id
      );

      if (response?.status === 200) {
        this.rolesUsuarioSelected = response.data;
        this.role_supervisor = this.rolesUsuarioSelected.some((item) => item.id === 403);
        this.viewProcesosReserva = this.rolesUsuarioSelected.some((item) => item.id === 404);
        this.acceso_rupes = this.rolesUsuarioSelected.some((item) => item.id === 405);
        this.add_alert_prov = this.rolesUsuarioSelected.some((item) => item.id === 406);
        this.rpt_proveedores = this.rolesUsuarioSelected.some((item) => item.id === 407);
        this.indicadoresInstitucionales = this.rolesUsuarioSelected.some((item) => item.id === 426);
      }

    },
    async updateRoles(idRole, estadoActual) {


      let response = {};
      let response2 = {};

      if (!estadoActual) {
        response2 = await this.services.Usuarios.deletetUsuarioRol({
          id_roles: [idRole],
          id_usuario: this.user_selected?.id,
        });
      } else if (estadoActual) {
        response = await this.services.Usuarios.postUsuarioRol({
          id_roles: [idRole],
          id_usuario: this.user_selected?.id,
        });
      }

      if (response?.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Rol agregado a usuario con éxito",
        });

        this.getSupervisores();
      }
      if (response2?.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Se le han revocado los permisos al usuario seleccionado",
        });

        this.getSupervisores()
      }
    },
    cleanModalAddSupervisor() {
      this.user_selected = null;
      this.role_supervisor = false;
      this.acceso_rupes = false;
      this.add_alert_prov = false;
      this.rpt_proveedores = false;
      this.viewProcesosReserva = false;
      this.indicadoresInstitucionales = false;
    },
    initCreated() {
      this.getSupervisores();
    },
  },
  watch: {
    search_ctl_usuarios(value) {
      if (value?.length >= 3) {
        this.loading_ctl_usuarios = true;
        this.buscarUsuario(value);
        this.loading_ctl_usuarios = false;
      }
    },
  },
  created() {
    this.initCreated();
  },
};
</script>